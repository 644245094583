
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

export default defineComponent({
  props: {
    competitions: Object,
  },
  setup() {
    const route = useRoute();
    console.log('router',route.params.subId);

    const { t, locale } = useI18n({
      useScope: "global",
    });
    const store = useStore();

    return {
      t,
      store,
      route,
    };
  },
});
