import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-116a8676"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row my-5" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "col-6 text-white nameCompetition" }
const _hoisted_4 = { class: "text-white" }
const _hoisted_5 = { class: "mb-4 text-white" }
const _hoisted_6 = {
  key: 0,
  class: "mb-1"
}
const _hoisted_7 = {
  key: 1,
  class: "text-white mb-4"
}
const _hoisted_8 = {
  key: 2,
  class: "text-white mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["m-auto text-center", _ctx.window.innerWidth > 575 ? `col-5 offset-1` : `col-6`])
    }, [
      _createElementVNode("img", {
        src: _ctx.competitions.logo ?? require('@/assets/reward-placeholder.svg'),
        class: "img-fluid"
      }, null, 8, _hoisted_2)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.competitions.name?.th ?? "-"), 1),
      _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.competitions.name?.en ?? "-"), 1),
      (!_ctx.route.params.subId)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.t("competition.organize")), 1))
        : _createCommentVNode("", true),
      (_ctx.store.state.currentLanguage === 'th')
        ? (_openBlock(), _createElementBlock("h5", _hoisted_7, _toDisplayString(_ctx.competition?.host?.th || "-"), 1))
        : (_openBlock(), _createElementBlock("h5", _hoisted_8, _toDisplayString(_ctx.competition?.host?.en || "-"), 1))
    ])
  ]))
}